import React, { useEffect } from "react";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import "./DashboardCard.css"; 

interface DashboardCardProps {
  id: string;
}

const DashboardCard: React.FC<DashboardCardProps> = ({ id }) => {
  const tokenUrl = `${process.env.REACT_APP_SYSTEM_DOMAIN}/guest-token/${id}`;

  const embedDomain = `${process.env.REACT_APP_SUPERSET_DOMAIN}`;

  const getToken = async () => {
    try {
      const response = await fetch(tokenUrl);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      return data.token;
    } catch (error) {
      console.error("Failed to fetch token:", error);
      return null;
    }
  };

  useEffect(() => {
    const embed = async () => {
      const token = await getToken();
      if (token) {
        const mountPoint = document.getElementById("dashboard");
        if (mountPoint) {
          await embedDashboard({
            id,
            supersetDomain: embedDomain,
            mountPoint,
            fetchGuestToken: () => token,
            dashboardUiConfig: {
              hideTitle: true,
              hideChartControls: true,
              hideTab: true,
            },
          });
        }
      }
    };
    embed();
  }, [id]);

  return <div id="dashboard" />;
};

export default DashboardCard;
