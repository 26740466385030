import React, { useState } from "react";
import { Box, Grid, Breadcrumbs, Link, Typography, Card, CardContent, CardActionArea, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import AppHeader from "../../layouts/AppHeader";
import DashboardCard from "../fleetanalytics/DashboardCard";

const FleetAnalyticsPage: React.FC = () => {
  const [selectedDashboardId, setSelectedDashboardId] = useState<string | null>(null);

  const dashboards = [
    {
      id: process.env.REACT_APP_DASHBOARD_CAR_ANALYSIS || "default-id",
      title: "Car Analysis",
      description: "View details on car model, registration, and export data."
    },
    {
      id: process.env.REACT_APP_DASHBOARD_FUEL_MANAGEMENT || "default-id",
      title: "Fuel Management",
      description: "Monitor fuel usage, distance covered, and costs."
    },
    {
      id: process.env.REACT_APP_DASHBOARD_DRIVER_BEHAVIOUR || "default-id",
      title: "Driver Behaviour Analysis",
      description: "Analyze driver behavior, including idle time and overspeeding."
    },
    {
      id: process.env.REACT_APP_DASHBOARD_BOOKINGS || "default-id",
      title: "Bookings",
      description: "View bookings data and manage booking approvals."
    },
    {
      id: process.env.REACT_APP_DASHBOARD_SERVICE_INSPECTION || "default-id",
      title: "Service & Inspection",
      description: "Track service schedules and inspection records."
    },
    {
      id: process.env.REACT_APP_DASHBOARD_PERFORMANCE || "default-id",
      title: "Performance",
      description: "Review car performance metrics and uptime."
    },
    {
      id: process.env.REACT_APP_DASHBOARD_HISTORY || "default-id",
      title: "History",
      description: "See car history, including travel data and usage."
    },
    {
      id: process.env.REACT_APP_DASHBOARD_GENERIC_REPORT || "default-id",
      title: "Generic Report",
      description: "Get an overall report including all metrics."
    },
  ];

  const handleDashboardClick = (id: string) => {
    setSelectedDashboardId(id);
  };

  const handleBreadcrumbClick = () => {
    setSelectedDashboardId(null);
  };

  return (
    <div className="App">
      <AppHeader />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f1f4f8",
          minHeight: "100vh",
          paddingX: { xs: "10px", sm: "20px", md: "30px", lg: "40px" },
        }}
      >
        <Grid>
          <Box sx={{ margin: "auto", width: "100%", maxWidth: "80%", paddingTop: "40px" }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link component={RouterLink} style={{ color: "#A2ADD0", fontSize: "14px" }} to="/dashboard">
                <Typography variant="body2">HOME</Typography>
              </Link>
              <Link
                component={RouterLink}
                style={{ color: "#4191FF", fontSize: "14px" }}
                to="/fleet-analytics"
                onClick={handleBreadcrumbClick}
              >
                <Typography variant="body2">FLEET ANALYTICS</Typography>
              </Link>
              <Typography color="#6495ED" fontSize="14px">
                {selectedDashboardId ? dashboards.find(dashboard => dashboard.id === selectedDashboardId)?.title : ""}
              </Typography>
            </Breadcrumbs>
            <Typography variant="h4" sx={{ textAlign: "center", marginBottom: "20px", fontSize: { xs: "1.5rem", md: "2rem" } }}>
                Fleet Report Dashboard
              </Typography>
          </Box>
        </Grid>

        <Box sx={{ marginTop: "20px", flexGrow: 1 }}>
          {selectedDashboardId ? (
            <DashboardCard id={selectedDashboardId} />
          ) : (
            <Box sx={{ padding: { xs: "10px", sm: "20px" }, backgroundColor: "#f1f4f8", width: "100%", flexGrow: 1 }}>
             
              <Grid container spacing={4} justifyContent="center">
                {dashboards.map((dashboard) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={dashboard.id}>
                    <Card
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        borderRadius: "16px",
                        boxShadow: "0 6px 18px rgba(0, 0, 0, 0.1)",
                        transition: "transform 0.2s, box-shadow 0.2s",
                        "&:hover": {
                          transform: "scale(1.05)",
                          boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
                        },
                      }}
                    >
                      <CardActionArea onClick={() => handleDashboardClick(dashboard.id)}>
                        <CardContent sx={{ padding: "20px" }}>
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{
                              fontSize: { xs: "1rem", md: "1.25rem" },
                              fontWeight: "bold",
                              color: "#333",
                              marginBottom: "10px",
                            }}
                          >
                            {dashboard.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                              fontSize: { xs: "0.9rem", md: "1rem" },
                              color: "#666",
                              lineHeight: 1.5,
                            }}
                          >
                            {dashboard.description}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <Box sx={{ textAlign: "center", padding: "15px" }}>
                        <Button
                          variant="contained"
                          onClick={() => handleDashboardClick(dashboard.id)}
                          sx={{
                            backgroundColor: "#4191FF",
                            color: "white",
                            padding: "8px 16px",
                            borderRadius: "8px",
                            fontWeight: "bold",
                            "&:hover": {
                              backgroundColor: "#6495ED",
                            },
                          }}
                        >
                          View Dashboard
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default FleetAnalyticsPage;

