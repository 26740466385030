import { Add as AddIcon } from "@mui/icons-material"
import {
  Box,
  Breadcrumbs,
  Card,
  Grid,
  LinearProgress,
  Link,
  Typography,
} from "@mui/material"
import { jwtDecode } from "jwt-decode"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch } from "../../config/store"

import AppHeader from "../../layouts/AppHeader"
import { clearAuthInfo } from "../../reducers/authInfoSlice"
import { DashboardData, FacilityPayload } from "../../services/data"
import "../../styles/dashboard.css"
import { DashboardModel } from "../../types/dashboard.model"
import { useNavigate } from "react-router-dom"

export default function Dashboard() {
  const authInfo = useSelector((state: any) => state.authInfo.data)
  const NewPassword = useSelector((state: any) => state.emailPassword)
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(true)
  const [matchingData, setMatchingData] = React.useState<
    DashboardModel[] | undefined
  >(undefined)
  const [, setRoleInfo] = React.useState([])
  

const normalizeRole = (role: string): string =>
  role.toLowerCase().replace(/[^a-z0-9]/g, '');


const getBaseRole = (role: string): string => {
  const baseRoles: { [key: string]: string } = {
    'fleetmanagement': 'fleetmanagement',
    'inventorymanagement': 'inventorymanagement',
    'assetmanagement': 'assetmanagement',
    'facilitymanagement': 'facilitymanagement',
    'vehicletracking': 'vehicletracking',
    'fleetanalytics': 'fleetanalytics',
  };

  for (const baseRole in baseRoles) {
    if (role.includes(baseRole)) {
      return baseRoles[baseRole];
    }
  }
  return role; 
};

React.useEffect(() => {
  const token = authInfo.access_token;
  const decoded = jwtDecode(token) as { [key: string]: any };
  const clientId = process.env.REACT_APP_CLIENT_ID;
  if (clientId) {
    const roleInfo = decoded.resource_access[clientId]?.roles || [];
    setRoleInfo(roleInfo);

    if (roleInfo && Array.isArray(roleInfo)) {
      const baseRoles = roleInfo.map(getBaseRole);
      const matchingItems = DashboardData.filter((item: DashboardModel) =>
        baseRoles.includes(normalizeRole(item.role))
      );
      setMatchingData(matchingItems);
    }
  } else {
    console.error("Client ID is undefined");
  }
}, [authInfo]);
  const passwordtextfield =btoa(NewPassword.password)

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 2000))
        setLoading(false)
      } catch (error) {
        console.error("Error fetching data:", error)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const facilityAuth = async (path: any) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_FACILITY_ENDPOINT}/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(FacilityPayload),
        }
      )

      if (response.ok) {
        const responseData = await response.json()
        const token = responseData.token
        window.open(`${path}?accessToken=${token}`, "_blank")
      } else {
        console.error("Login failed")
      } 
    } catch (error) {
      console.error("Error during login:", error)
    }
  } 
  const refreshToken = authInfo.refresh_token
  const accessToken = authInfo.access_token

  const traccarAuth = (path: string) => {

    const childWindow = window.open(`${path}/?refreshToken=${refreshToken}&accessToken=${accessToken}&password=${passwordtextfield}`, "_blank")

    if (!childWindow) {
      console.error("Child window could not be opened")
    } else {
      window.addEventListener("message", (event) => {
        if (event.source === childWindow && event.data.exit) {
          dispatch(clearAuthInfo())
        }
      })
    }
  }
  const inventoryAuth = (path: string) => {

    const childWindow = window.open(`${path}/?accessToken=${accessToken}&refreshToken=${refreshToken}`, "_blank")

    if (!childWindow) {
      console.error("Child window could not be opened")
    } else {
      window.addEventListener("message", (event) => {
        if (event.source === childWindow && event.data.exit) {
          dispatch(clearAuthInfo())
        }
      })
    }
  }
  const fleetmanager = (path: string) => {

    const childWindow = window.open(`${path}/?accessToken=${accessToken}&refreshToken=${refreshToken}`, "_blank")

    if (!childWindow) {
      console.error("Child window could not be opened")
    } else {
      window.addEventListener("message", (event) => {
        if (event.source === childWindow && event.data.exit) {
          dispatch(clearAuthInfo())
        }
      })
    }
  }

  const handelRedirection = (data: any) => {
    if (data !== null) {
      switch (data.id) {
        case 1:
          facilityAuth(data.path)
          break
        case 2:
          fleetmanager(data.path)
          break
        case 3:
          inventoryAuth(data.path)
          break
        case 5:
          traccarAuth(data.path)
          break
        case 6:
          navigate("/fleet-analytics") 
          break
        default:
          break
      }
    }
  }
  


  return (
    <>
      {loading ? (
        <LinearProgress color="primary" />
      ) : (
        <Box sx={{ background: "#f1f4f7",minHeight: "100vh" }}>
          <AppHeader />
          <Box
            sx={{
              height: {
                xl: "100vh",
                lg: "100vh",
                md:
                  matchingData && matchingData.length === 0 ? "100vh" : "100%",
                sm:
                  matchingData && matchingData.length === 0 ? "100vh" : "100%",
                xs:
                  matchingData && matchingData.length === 0 ? "100vh" : "100%",
              },
              margin: "auto",
              width: "80%",
            }}

          >
            <Box style={{ padding: "40px 0px" }}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="none"
                  style={{ color: "#A2ADD0", fontSize: "14px" }}
                  href="/dashboard"
                >
                  HOME
                </Link>
                <Typography
                  color="#4191FF"
                  fontSize="14px"
                  sx={{ cursor: "pointer" }}
                >
                  DASHBOARD
                </Typography>
              </Breadcrumbs>
            </Box>
            <Box>
              {!loading &&
                (matchingData && matchingData.length === 0 ? (
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                      <Card
                        sx={{
                          width: "100%",
                          minHeight: "170px",
                          display: "flex",
                        }}
                        className="card-design"
                      >
                        <Grid
                          container
                          spacing={0}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{ textAlign: "center" }}
                          >
                            <AddIcon
                              fontSize="small"
                              sx={{
                                color: "grey",
                                padding: "20px",
                                borderRadius: "50%",
                                background: "#e4e5e7",
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                ) : (
                  <Box>
                    <Grid container spacing={4}>
                      {matchingData &&
                        matchingData.map((item, index) => (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={4}
                            sx={{
                              cursor: "pointer",
                            }}
                            key={item.id}
                          >
                            <Card
                              className="card-design"
                              onClick={() => handelRedirection(item)}
                              sx={{
                                width: "100%",
                                minHeight: "170px",
                                display: "flex",
                                borderRadius: "10px",
                                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                                backgroundColor: "#f9f9f9",
                                transition: "all 0.3s ease",
                                "&:hover": {
                                  transform: "scale(1.05)",
                                  boxShadow: "0 8px 24px rgba(0, 0, 0, 0.2)",
                                },
                              }}
                            >
                              <Grid
                                container
                                spacing={0}
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                  padding: "0px 10px",
                                  height: "170px",
                                  maxHeight: "170px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={3}
                                  md={3}
                                  lg={3}
                                  xl={3}
                                  sx={{ px: 2 }}
                                >
                                  <img
                                    src={item.image}
                                    alt="facility_missing"
                                    className="dashboard-image"
                                    style={{
                                      maxWidth: "100%",
                                      height: "auto",
                                      marginLeft: "auto",
                                      marginRight: "auto",
                                      display: "block",
                                      ...(window.innerWidth <= 600 && {
                                        display: "block",
                                      }),
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={9}
                                  md={9}
                                  lg={9}
                                  xl={9}
                                  sx={{ px: 2 }}
                                >
                                  <Box>
                                    <Typography
                                      variant="h6"
                                      color="#282828"
                                      fontWeight={700}
                                      sx={{
                                        whiteSpace: "nowrap",
                                        "@media (max-width: 600px)": {
                                          fontSize: "16px",
                                        },
                                      }}
                                    >
                                      {item.title}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      mt: 2,
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      textAlign: "left",
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      color="#A2ADD0"
                                      fontSize={14}
                                      className="description"
                                    >
                                      {item.short_description}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                        ))}
                      {matchingData &&
                        matchingData.length < DashboardData.length + 1 && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={4}
                            sx={{
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <Card className="card-design">
                              <Grid
                                container
                                spacing={0}
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                  padding: "0px 10px",
                                  height: "170px",
                                  maxHeight: "170px",
                                }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  sx={{ textAlign: "center" }}
                                >
                                  <AddIcon
                                    fontSize="small"
                                    sx={{
                                      color: "grey",
                                      padding: "20px",
                                      borderRadius: "50%",
                                      background: "#e4e5e7",
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                        )}
                    </Grid>
                  </Box>
                ))}
            </Box>
          </Box>

          <Box className="app-footer">
            © 2024 Copyright{" "}
            <span className="app-footer-link">10XTECHNOLOGIES</span>. All Rights
            Reserved
          </Box>
        </Box>
      )}
    </>
  )
}



